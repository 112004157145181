var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "account_login"
  }, [_c('div', {
    staticClass: "top"
  }, [_c('loginHead')], 1), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "small_logo",
    on: {
      "click": _vm.toHomePage
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/login/small_logo.png")
    }
  })]), _c('div', {
    staticClass: "main"
  }, [_c('main', [_c('div', {
    staticClass: "login_top"
  }, [_vm._m(0), _c('div', {
    staticClass: "login_title"
  }, [_vm._v(_vm._s(_vm.$t('title.bindingAccout')))])]), _c('div', {
    staticClass: "login_change"
  }, [_c('div', {
    staticClass: "item",
    on: {
      "click": function ($event) {
        _vm.curSelect = 1;
      }
    }
  }, [_c('div', {
    staticClass: "item_title",
    style: {
      color: _vm.curSelect == 1 ? '#F39800' : '#999999'
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('set.bindingPhone')) + " ")]), _vm.curSelect == 1 ? _c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/login/select.png")
    }
  })]) : _vm._e()]), _c('div', {
    staticClass: "item",
    on: {
      "click": function ($event) {
        _vm.curSelect = 2;
      }
    }
  }, [_c('div', {
    staticClass: "item_title",
    style: {
      color: _vm.curSelect == 2 ? '#F39800' : '#999999'
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('set.bindingEmail')) + " ")]), _vm.curSelect == 2 ? _c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/login/select.png")
    }
  })]) : _vm._e()])]), _c('div', {
    staticClass: "login_input"
  }, [_vm.curSelect == 1 ? _c('div', {
    staticClass: "input_phone"
  }, [_vm._m(1), _c('div', {
    staticClass: "area_code"
  }, [_c('el-dropdown', {
    on: {
      "command": _vm.areaCodeClick
    }
  }, [_c('span', {
    staticClass: "el-dropdown-link"
  }, [_vm._v(" " + _vm._s(_vm.areaCode)), _c('i', {
    staticClass: "el-icon-caret-bottom el-icon--right"
  })]), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.areaCodeList, function (item, index) {
    return _c('el-dropdown-item', {
      key: index,
      attrs: {
        "command": item
      }
    }, [_vm._v(_vm._s(item) + " ")]);
  }), 1)], 1)], 1), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.mobile,
      expression: "mobile"
    }],
    staticStyle: {
      "width": "270px"
    },
    attrs: {
      "type": "number",
      "placeholder": _vm.$t('login.inputPhone')
    },
    domProps: {
      "value": _vm.mobile
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.mobile = $event.target.value;
      }
    }
  })])]) : _vm._e(), _vm.curSelect == 2 ? _c('div', {
    staticClass: "input_phone"
  }, [_vm._m(2), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user_email,
      expression: "user_email"
    }],
    staticStyle: {
      "width": "320px"
    },
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('login.inputEmail')
    },
    domProps: {
      "value": _vm.user_email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.user_email = $event.target.value;
      }
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "input_phone"
  }, [_vm._m(3), _c('div', {
    staticClass: "input"
  }, [(_vm.showPassword ? 'text' : 'password') === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password,
      expression: "password"
    }],
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "placeholder": _vm.$t('login.inputPassword'),
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.password) ? _vm._i(_vm.password, null) > -1 : _vm.password
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.password,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.password = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.password = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.password = $$c;
        }
      }
    }
  }) : (_vm.showPassword ? 'text' : 'password') === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password,
      expression: "password"
    }],
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "placeholder": _vm.$t('login.inputPassword'),
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.password, null)
    },
    on: {
      "change": function ($event) {
        _vm.password = null;
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password,
      expression: "password"
    }],
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "placeholder": _vm.$t('login.inputPassword'),
      "type": _vm.showPassword ? 'text' : 'password'
    },
    domProps: {
      "value": _vm.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.password = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "is_show",
    on: {
      "click": function ($event) {
        _vm.showPassword = !_vm.showPassword;
      }
    }
  }, [_vm.showPassword ? _c('img', {
    attrs: {
      "src": require("../../assets/login/show.png")
    }
  }) : _c('img', {
    attrs: {
      "src": require("../../assets/login/hide.png")
    }
  })])])]), _c('div', {
    staticClass: "btn",
    on: {
      "click": _vm.confirmClick
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('confirm')) + " ")])])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/login/flag.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "input_img"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/login/phone.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "input_img"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/login/email.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "input_img"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/login/password.png")
    }
  })]);
}];
export { render, staticRenderFns };